import { Selector } from '@ngxs/store';

import { AppUiState } from './app-ui.state';
import { AppUiStateModel } from './app-ui.model';

export class AppUiSelector {
  @Selector([AppUiState])
  static uuid(state: AppUiStateModel): string {
    return state.uuid;
  }

  @Selector([AppUiState])
  static isExpand(state: AppUiStateModel): boolean {
    return state.isExpand;
  }

  @Selector([AppUiState])
  static isLoading(state: AppUiStateModel): boolean {
    return state.isLoading;
  }
}
