export class AppUiLoadingAction {
  static readonly type = '[AppUi] AppUiLoadingAction';

  constructor(public isLoading = false) {}
}

export class AppUiSideNavExpandCollapseAction {
  static readonly type = '[AppUi] AppUiSideNavExpandCollapseAction';

  constructor(public isExpand = true) {}
}

export class AppUiContentWrapperAction {
  static readonly type = '[AppUi] AppUiContentWrapperAction';

  constructor() {}
}
