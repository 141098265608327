import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { v4 as uuidV4 } from 'uuid';

import { AppUiStateModel } from './app-ui.model';
import {
  AppUiLoadingAction,
  AppUiSideNavExpandCollapseAction,
} from './app-ui.action';

const appStateDefaults: AppUiStateModel = {
  uuid: uuidV4(),
  isExpand: true,
  isLoading: false,
};

@State<AppUiStateModel>({
  name: 'AppUi',
  defaults: appStateDefaults,
})
@Injectable()
export class AppUiState {
  @Action(AppUiSideNavExpandCollapseAction)
  sideNavExpandCollapseAction(
    { patchState }: StateContext<AppUiStateModel>,
    action: AppUiSideNavExpandCollapseAction,
  ) {
    patchState({ isExpand: action.isExpand });
  }

  @Action(AppUiLoadingAction)
  loadingAction(
    { patchState }: StateContext<AppUiStateModel>,
    action: AppUiLoadingAction,
  ) {
    patchState({ isLoading: action.isLoading });
  }
}
